/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines */
import { action, computed, observable } from 'mobx';
import { CssVariablesTheme } from 'src/styles/CssVariablesTheme';

export class ThemeStoreClass {
    // Header
    @observable headerColor: string = CssVariablesTheme.headerColor;

    @observable headerBackground: string = CssVariablesTheme.headerBackground;

    @observable headerApplyBtnBg: string = CssVariablesTheme.headerApplyBtnBg;

    @observable headerApplyBtnBorderColor: string =
        CssVariablesTheme.headerApplyBtnBorderColor;

    @observable headerApplyBtnColor: string =
        CssVariablesTheme.headerApplyBtnColor;

    @observable headerActiveLinkColor: string =
        CssVariablesTheme.headerActiveLinkColor;

    @observable headerFontSize: string = CssVariablesTheme.headerFontSize;

    @observable logoMaxSize: string = CssVariablesTheme.logoMaxSize;

    /* Body*/
    @observable formTextColor: string = CssVariablesTheme.formTextColor;

    @observable background: string = CssVariablesTheme.background;

    @observable font: string = CssVariablesTheme.font;

    /* Form*/
    @observable labelColor: string = CssVariablesTheme.labelColor;

    @observable inputBorderColor: string = CssVariablesTheme.inputBorderColor;

    @observable inputBg: string = CssVariablesTheme.inputBg;

    @observable inputBorderColorHover: string =
        CssVariablesTheme.inputBorderColorHover;

    @observable inputBorderRadius: string = CssVariablesTheme.inputBorderRadius;

    @observable calculatorSecondaryText: string =
        CssVariablesTheme.calculatorSecondaryText;

    @observable tabsColor: string = CssVariablesTheme.tabsColor;

    @observable calculatorSliderTrack: string =
        CssVariablesTheme.calculatorSliderTrack;

    @observable calculatorInputColor: string =
        CssVariablesTheme.calculatorInputColor;

    @observable calculatorSliderHandleBg: string =
        CssVariablesTheme.calculatorSliderHandleBg;

    @observable calculatorSliderHandleBorder: string =
        CssVariablesTheme.calculatorSliderHandleBorder;

    @observable tabsBorderBottomColor: string =
        CssVariablesTheme.tabsBorderBottomColor;

    @observable activeTabColor: string = CssVariablesTheme.activeTabColor;

    @observable activeTabBorderColor: string =
        CssVariablesTheme.activeTabBorderColor;

    @observable itemListBg: string = CssVariablesTheme.itemListBg;

    @observable basePageMaxWidth: string = CssVariablesTheme.basePageMaxWidth;

    @observable basePageWidth: string = CssVariablesTheme.basePageWidth;

    /* Footer*/
    @observable footerBg: string = CssVariablesTheme.footerBg;

    @observable footerTextColor: string = CssVariablesTheme.footerTextColor;

    @observable formButtonsBg: string = CssVariablesTheme.formButtonsBg;

    @observable formButtonsColor: string = CssVariablesTheme.formButtonsColor;

    /* Statuses color */

    @observable statusColorsPending: Record<string, string> =
        CssVariablesTheme.pending;

    @observable statusColorsActive: Record<string, string> =
        CssVariablesTheme.active;

    @observable statusColorsRepaid: Record<string, string> =
        CssVariablesTheme.repaid;

    @observable statusColorsOverdue: Record<string, string> =
        CssVariablesTheme.overdue;

    @observable statusColorsWrittenOff: Record<string, string> =
        CssVariablesTheme.writtenOff;

    @observable statusColorsProcessing: Record<string, string> =
        CssVariablesTheme.processing;

    @observable statusColorsError: Record<string, string> =
        CssVariablesTheme.error;

    @observable statusColorsOnKyc: Record<string, string> =
        CssVariablesTheme.onKyc;

    @observable statusColorsVerification: Record<string, string> =
        CssVariablesTheme.verification;

    @observable statusColorsUpdateRequested: Record<string, string> =
        CssVariablesTheme.updateRequested;

    @observable statusColorsUnderwriting: Record<string, string> =
        CssVariablesTheme.underwriting;

    @observable statusColorsDeclined: Record<string, string> =
        CssVariablesTheme.declined;

    @observable statusColorsCanceled: Record<string, string> =
        CssVariablesTheme.canceled;

    @observable statusColorsApproved: Record<string, string> =
        CssVariablesTheme.approved;

    @observable statusColorsDisbursed: Record<string, string> =
        CssVariablesTheme.disbursed;

    @observable isFilledStatus: boolean = CssVariablesTheme.isFilledStatus;

    @action setHeaderBg(color: string) {
        this.headerBackground = color;
    }

    @action setHeaderColor(color: string) {
        this.headerColor = color;
    }

    @action setApplyBtnBg(color: string) {
        this.headerApplyBtnBg = color;
    }

    @action setApplyBtnColor(color: string) {
        this.headerApplyBtnColor = color;
    }

    @action setFontSize(size: string) {
        this.headerFontSize = `${size}px`;
    }

    @action setActiveLinkColor(color: string) {
        this.headerActiveLinkColor = color;
    }

    @action setFormTextColor(color: string) {
        this.formTextColor = color;
    }

    @action setBackgroundColor(color: string) {
        this.background = color;
    }

    @action setLabelColor(color: string) {
        this.labelColor = color;
    }

    @action setInputBorderColor(color: string) {
        this.inputBorderColor = color;
    }

    @action setInputBg(color: string) {
        this.inputBg = color;
    }

    @action setInputBorderColorHover(color: string) {
        this.inputBorderColorHover = color;
    }

    @action setInputBorderRadius(radius: string) {
        this.inputBorderRadius = `${radius}px`;
    }

    @action setFooterBg(color: string) {
        this.footerBg = color;
    }

    @action setFooterTextColor(color: string) {
        this.footerTextColor = color;
    }

    @action setFormButtonBg(color: string) {
        this.formButtonsBg = color;
    }

    @action setFont(font: string) {
        this.font = font;
    }

    @action setLogoMaxSize(size: number) {
        this.logoMaxSize = `${size}px`;
    }

    @action setDefaultStyles() {
        this.headerColor = CssVariablesTheme.headerColor;
        this.headerBackground = CssVariablesTheme.headerBackground;
        this.headerApplyBtnBg = CssVariablesTheme.headerApplyBtnBg;
        this.headerApplyBtnColor = CssVariablesTheme.headerApplyBtnColor;
        this.headerFontSize = CssVariablesTheme.headerFontSize;
        this.headerActiveLinkColor = CssVariablesTheme.headerActiveLinkColor;
        this.formTextColor = CssVariablesTheme.formTextColor;
        this.background = CssVariablesTheme.background;
        this.labelColor = CssVariablesTheme.labelColor;
        this.inputBorderColor = CssVariablesTheme.inputBorderColor;
        this.inputBg = CssVariablesTheme.inputBg;
        this.inputBorderColorHover = CssVariablesTheme.inputBorderColorHover;
        this.inputBorderRadius = CssVariablesTheme.inputBorderRadius;
        this.footerBg = CssVariablesTheme.footerBg;
        this.footerTextColor = CssVariablesTheme.footerTextColor;
        this.formButtonsBg = CssVariablesTheme.formButtonsBg;
        this.font = CssVariablesTheme.font;
        this.logoMaxSize = CssVariablesTheme.logoMaxSize;
    }

    @computed get getHeaderColor() {
        return this.headerColor;
    }

    @computed get getHeaderBackground() {
        return this.headerBackground;
    }

    @computed get getHeaderApplyBtnBg() {
        return this.headerApplyBtnBg;
    }

    @computed get getHeaderFontSize() {
        return this.headerFontSize;
    }

    @computed get getInputBorderRadius() {
        return this.inputBorderRadius;
    }

    @computed get getHeaderActiveLinkColor() {
        return this.headerActiveLinkColor;
    }

    @computed get getFormTextColor() {
        return this.formTextColor;
    }

    @computed get getBackground() {
        return this.background;
    }

    @computed get getLabelColor() {
        return this.labelColor;
    }

    @computed get getInputBorderColor() {
        return this.inputBorderColor;
    }

    @computed get getInputBg() {
        return this.inputBg;
    }

    @computed get getFooterTextColor() {
        return this.footerTextColor;
    }

    @computed get getFormButtonsBg() {
        return this.formButtonsBg;
    }

    @computed get getHeaderApplyBtnColor() {
        return this.headerApplyBtnColor;
    }

    @computed get getFooterBg() {
        return this.footerBg;
    }

    @computed get getFont() {
        return this.font;
    }

    @computed get getLogoMaxSize() {
        return this.logoMaxSize;
    }

    @computed get getBasePageMaxWidth() {
        return this.basePageMaxWidth;
    }

    @computed get getBasePageWidth() {
        return this.basePageWidth;
    }

    @computed get getThemeVariables() {
        return {
            headerColor: this.headerColor,
            headerBackground: this.headerBackground,
            headerApplyBtnBg: this.headerApplyBtnBg,
            headerApplyBtnColor: this.headerApplyBtnColor,
            headerApplyBtnBorderColor: this.headerApplyBtnBorderColor,
            headerFontSize: this.headerFontSize,
            headerActiveLinkColor: this.headerActiveLinkColor,
            formTextColor: this.formTextColor,
            background: this.background,
            labelColor: this.labelColor,
            inputBorderColor: this.inputBorderColor,
            inputBg: this.inputBg,
            inputBorderColorHover: this.inputBorderColorHover,
            inputBorderRadius: this.inputBorderRadius,
            footerBg: this.footerBg,
            footerTextColor: this.footerTextColor,
            formButtonsBg: this.formButtonsBg,
            formButtonsColor: this.formButtonsColor,
            font: this.font,
            logoMaxSize: this.logoMaxSize,
            tabsColor: this.tabsColor,
            calculatorSecondaryText: this.calculatorSecondaryText,
            calculatorSliderTrack: this.calculatorSliderTrack,
            calculatorInputColor: this.calculatorInputColor,
            calculatorSliderHandleBg: this.calculatorSliderHandleBg,
            calculatorSliderHandleBorder: this.calculatorSliderHandleBorder,
            tabsBorderBottomColor: this.tabsBorderBottomColor,
            activeTabColor: this.activeTabColor,
            activeTabBorderColor: this.activeTabBorderColor,
            itemListBg: this.itemListBg,
            statusColorsPending: this.statusColorsPending,
            statusColorsActive: this.statusColorsActive,
            statusColorsRepaid: this.statusColorsRepaid,
            statusColorsOverdue: this.statusColorsOverdue,
            statusColorsWrittenOff: this.statusColorsWrittenOff,
            statusColorsProcessing: this.statusColorsProcessing,
            statusColorsError: this.statusColorsError,

            statusColorsOnKyc: this.statusColorsOnKyc,
            statusColorsVerification: this.statusColorsVerification,
            statusColorsUpdateRequested: this.statusColorsUpdateRequested,
            statusColorsUnderwriting: this.statusColorsUnderwriting,
            statusColorsDeclined: this.statusColorsDeclined,
            statusColorsCanceled: this.statusColorsCanceled,
            statusColorsApproved: this.statusColorsApproved,
            statusColorsDisbursed: this.statusColorsDisbursed,

            isFilledStatus: this.isFilledStatus,
        };
    }
}

export const ThemeStore = new ThemeStoreClass();
