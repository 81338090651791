export const CssVariablesTheme = {
    // Header
    headerColor: '#323232',
    headerBackground: '#ffffff',
    headerApplyBtnBg: '#BED600',
    headerApplyBtnBorderColor: '#BED600',
    headerApplyBtnColor: '#1B1464',
    headerFontSize: '16px',
    headerActiveLinkColor: '#3A2BD6',
    logoMaxSize: '100%',

    /* Body*/
    formTextColor: '',
    background: '#F7F7F7',
    font: 'SF Pro Text',
    fontSize: '16px',
    itemListBg: '#BED600B2',
    basePageWidth: '',
    basePageMaxWidth: '',

    /* Form*/
    labelColor: '#444444',
    inputBorderColor: '#FBFAFF',
    inputBg: '#FBFAFF',
    inputBorderColorHover: '#26BFC2',
    inputBorderRadius: '20px',
    formButtonsBg: '#3A2BD6',
    formButtonsColor: '#FFFFFF',
    tabsColor: '#382ACE80',
    calculatorSecondaryText: '#979797',
    calculatorSliderTrack: '#382ACE',
    calculatorInputColor: '#979797',
    calculatorSliderHandleBg: '#382ACE',
    calculatorSliderHandleBorder: '#FFFFFFDE',
    tabsBorderBottomColor: 'transparent',
    activeTabColor: '#382ACE',
    activeTabBorderColor: '#382ACE',

    /* Footer*/
    footerBg: '#ffffff',
    footerTextColor: '#97a8b8',

    /* Statuses color */
    pending: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    active: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },
    repaid: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    overdue: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    writtenOff: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    processing: {
        color: '#000000',
        borderColor: '#E3DB40',
        backgroundColor: '#E3DB40',
    },
    error: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    onKyc: {
        color: '#000000',
        borderColor: '$9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    verification: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    updateRequested: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    underwriting: {
        color: '#000000',
        borderColor: '#9B9BA4',
        backgroundColor: '#9B9BA4',
    },
    declined: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    canceled: {
        color: '#000000',
        borderColor: '#F33F4A',
        backgroundColor: '#F33F4A',
    },
    approved: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },
    disbursed: {
        color: '#000000',
        borderColor: '#3FE579',
        backgroundColor: '#3FE579',
    },

    isFilledStatus: false,
};
