// eslint-disable-next-line simple-import-sort/sort
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FormioCustomEvent, FormioEvent } from 'src/core/Formio.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';
import { TaskViewViewModel } from 'src/components/TaskViewViewModel';
import { useTaskInterval } from 'src/hooks/useTaskInterval';
// import form from 'src/components/form.json';

export type TaskFormProps = {
    taskId: string;
};

export const TaskForm = observer(({ taskId }: TaskFormProps) => {
    const { t } = useTranslation();
    const { taskDefinition } = TaskViewViewModel;
    const { isLoading } = TaskViewViewModel;

    useLayoutEffect(() => {
        TaskViewViewModel.loadItem(taskId);

        return () => {
            TaskViewViewModel.reset();
        };
    }, [taskId]);

    useTaskInterval(taskDefinition);

    if (isLoading) {
        return <StyledSpinner />;
    }

    if (!taskDefinition && !isLoading) {
        return <Redirect to={RoutePaths.index} />;
    }

    const hasL10nTitleKey = Boolean(taskDefinition?.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${taskDefinition?.extensions?.l10nTitleKey}`, {
              ...taskDefinition?.taskInfo,
          })
        : taskDefinition?.name;

    return (
        <>
            <StyledTaskHeader>{title}</StyledTaskHeader>
            {/* <div class="stepper">
                <div class="step completed">
                    <span class="circle">
                    <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAArCAYAAADG8/AoAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHdSURBVHgB7dixTsJAHMfx//VOEzHRJjIQceAJDOEJYOMtJIbdRwDfBEc3N0cYnZTVSRf3RqOigZz9DRBUoNf27tpr+E7X693wyTVNWqJt25yPUUHz/Y5fEuKCSVYjJoNvxq4Kia36nTpxMQyP0l+eLxx2HRQVCrsJigqDjYKiQmBVoMh5rCoUOY2NAyUpA2excaE0m7WcxCaBvgSDsXPYpFBcOoVNA0XOYNNCkRNYHVCUe6wuKMo1VicU5RarG4r+YatH5x1i3lk4bJJkz+GH7837dHoZBIOALGUCin5hj8vdXjjRX7FuHIJbNsCmoGiBrfidGhfiacNa42CTUOQtRkLUItbW94UY4t8OGcg0FC2wO1KqIIyAbUDRAstnsxFJUnlEtYJtQRGfD4LJeHKwV/8ixtoK+yq7ntfmu6fXk3AfJcwmFPHli7fPh7vDUgMvrabC3lRg21DE/068ftyPTIOzgCK+atIkOCso4utumABnCUV8002d4KyhiEct0AHOAxRFYlEacF6gSAmLkoD3S41Hj3m3eYCi2N+zJ+VuXxL1SGcWoEj5ZOfFPOHoLEFRbCzSBrYIRYmwKDXYMhQlxqLE4AygKBUWxQZnBEWpsUgZnCEUacGiSHDGUPQDmgfamM307RsAAAAASUVORK5CYII=" />
                    <div class='line' ></div>
                    </span>
                    <span class="label">Personal Information</span>
                </div>
                <div class="step active">
                    <span class="circle">2<div class='line' ></div></span>
                    <span class="label">Bank Information</span>
                </div>
                <div class="step">
                    <span class="circle">3
                    <div class='line' ></div>
                    </span>
                    <span class="label">Employment Information</span>
                </div>
                <div class="step">
                    <span class="circle">4
                    <div class='line'></div>
                    </span>
                    <span class="label">Loan Information</span>
                </div>
            </div> */}
            <FormioForm
                key={taskId}
                form={TaskViewViewModel.taskForm as any}
                submission={TaskViewViewModel.taskVariables}
                onSubmit={getSubmitHandler(taskId)}
                onCustomEvent={getCustomEventHandler(taskId)}
                normalizeSubmission
            />
        </>
    );
});

const StyledTaskHeader = styled.h1`
    margin-bottom: 0;

    @media (max-width: 576px) {
        font-size: 32px;
    }
`;

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const getSubmitHandler = (taskId: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        const { data } = values;
        await submitForm(taskId, data);
    };
};

const submitForm = async (taskId: string, data: any) => {
    const tasks = await CurrentTaskStore.submitForm(taskId, data);

    if (tasks?.length) {
        Router.navigate(
            UrlHelper.getTargetUrl(RoutePaths.task, {
                taskId: tasks[0].id!,
            }),
        );
    } else {
        Router.navigate(RoutePaths.index);
    }
};

const getCustomEventHandler = (processKey: string) => {
    return async (event: FormioCustomEvent<'submitWithoutData'>) => {
        if (event.type === 'submitWithoutData') {
            const data = _.pick(event.data, [event.component.key]);
            await submitForm(processKey, data);
        }
    };
};
